
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, onMounted, watch } from 'vue';
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampEmptyListFeedbackV2
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { useFilterMainStore } from '@/store/FilterMainStore';
import { useCompanyStore } from '@/store/CompanyStore';
import moment from 'moment';
import SkeletonComponent from '@/components/SkeletonComponent.vue';
import { CampModalMediaFromURL } from '@/components';
import CampModalDelRegister from '@/components/CampModalDelRegister.vue';
import { campHandleLargeDescription } from '@/composables/DataValidation';

export interface IAwardData {
    id?: number,
    title: string,
    description: string,
    media: string,
    active: boolean | number,
    points: number,
    expiration_date: string
    id_company: number | null,
  }

interface IAwardResponse {
  data: {
    data: IAwardData[]
    error: boolean,
    message: string,
    errorCode: string
  }
}

type _TMediaModal = {
  URL: string;
  contentType: string;
  typeYoutubeToggle: boolean;
  typeVideoToggle: boolean;
  typeImgToggle: boolean;
  typePdfToggle: boolean;
}

export const _initMediaModal: _TMediaModal = {
  URL: "",
  contentType: "",
  typeYoutubeToggle: false,
  typeVideoToggle: false,
  typeImgToggle: false,
  typePdfToggle: false,
}

type TMediaModal = {
  URL: string;
  toggle: boolean
}
export const initMediaModal: TMediaModal = {
  URL: "",
  toggle: false
}

export default defineComponent({
  name: "AwardsCatalogList",
  components: {
    CampHeader,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampEmptyListFeedbackV2,
    SkeletonComponent,
    CampModalMediaFromURL,
    CampModalDelRegister
  },
  setup() {
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const filterMainStore = useFilterMainStore()
    const mediaModal = ref({ ...initMediaModal })
    const activeStatus = ref(true);
    const companyStore = useCompanyStore()
    const awardList = ref<IAwardData[] | null>(null)
    const awardListRender = ref<IAwardData[] | null>(null)
    const keywordSearch = ref("")
    const isLoading = ref(false)
    const awardInfo = ref<IAwardData | null>(null)
    const toggleModalDelRegister = ref(false)
    const itemDel = ref<IAwardData | null>(null)


    // Functions
    async function getAwardList() {
      if(!companyStore.getId) return
      keywordSearch.value = ""
      try {
        isLoading.value = true
        awardListRender.value = null
        const companyId = companyStore.getId
        const url = `/api/getRewardList?id_company=${companyId}`
        const response:IAwardResponse = await axios.get(url)
        const { data } = response.data
        awardList.value = data.map(item => ({ ...item, active: !!item.active }))
        awardListRender.value = [ ...awardList.value ]
      } catch (error) {
        if(error instanceof AxiosError) {
          showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
        }
      } finally {
        isLoading.value = false
        loaderStore.close()
      }
    }


    function handleFilterObjectsByKeyword(): boolean {
      if(!awardList.value)
        return true
        awardListRender.value = awardList.value.map(el => ({ ...el, checked: false })).filter((item) => {
        for (const key in item) {
          if (key !== 'checked') {
            const propValue = item[key];
            if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
              return true;
            }
            if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
              return true;
            }
          }
        }
        return false;
      });
      return false
    }

    async function handleAwardActiveToggle(award) {
      const updatedActiveStatus = award.active ? 0 : 1
      try {
        loaderStore.open()
        await axios.put(`/api/toggleRewardStatus`, { id: award.id, active: updatedActiveStatus });
        award.active = updatedActiveStatus
        getAwardList()
      } catch (error) {
        if (error instanceof AxiosError) {
          showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        isLoading.value = true
        loaderStore.close()
      }
    }

    function handleModalDelRegister(item: IAwardData) {
      toggleModalDelRegister.value = !toggleModalDelRegister.value
      itemDel.value = item
    }

    async function handleConfirmItemDel() {
      if(!itemDel.value){
        return   
      }
      try {
        loaderStore.open()
        const result = await axios.delete(`/api/deleteReward/${itemDel.value.id}`)
        showTimeAlert(`O prêmio ${itemDel.value.title} foi excluído com sucesso!`)
        getAwardList()
        itemDel.value = null
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally {
        loaderStore.close()
      }
    }



    /** Handle Filter Modal */
    function handleInitFilterModal() {
      if(!companyStore.getId) {
        filterMainStore.hideModal()
        filterMainStore.showModal()
      }
    }

    /** Handle Open Media Modal */
    async function handleMediaModal(URL: string) {
      mediaModal.value.URL = URL
      mediaModal.value.toggle = !mediaModal.value.toggle
    }

    // Life Cycles
    onMounted(() => {
      getAwardList()
      handleInitFilterModal()
    })

    watch(() => { companyStore.getId }, () => getAwardList(), { deep: true })

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

    return {
      isLoading,
      activeStatus,
      keywordSearch,
      companyStore,
      awardList,
      awardListRender,
      handleFilterObjectsByKeyword,
      moment,
      mediaModal,
      handleMediaModal,
      awardInfo,
      handleAwardActiveToggle,
      toggleModalDelRegister,
      itemDel,
      handleConfirmItemDel,
      handleModalDelRegister,
      campHandleLargeDescription
    }
  }
})
